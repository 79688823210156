import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { Routers } from './router';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Themes from './themes';
import Loading from './components/loading';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);
const theme = createTheme(Themes('dark'));

console.log(`You're in the ${process.env.REACT_APP_ENV} environment.`);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<Loading />}>
        <RouterProvider router={Routers} />
      </Suspense>
    </ThemeProvider>
  </React.StrictMode>,
);
