import React from 'react';
import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

const Homepage = lazy(() => import('./pages/homepage'));
const Login = lazy(() => import('./pages/login'));
const Register = lazy(() => import('./pages/register'));
const Logout = lazy(() => import('./pages/logout'));
const NotFound = lazy(() => import('./pages/notFound'));
const PrivacyAgreement = lazy(() => import('./pages/agreements/privacy'));
const UsageAgreement = lazy(() => import('./pages/agreements/usage'));
const ChargeAgreement = lazy(() => import('./pages/agreements/charge'));
const DiscordCallback = lazy(() => import('./pages/discordCallback'));

export const Routers = createBrowserRouter([
  { path: '/', element: <Homepage /> },
  // { path: '/login', element: <Login /> },
  // { path: '/logout', element: <Logout /> },
  // { path: '/register', element: <Register /> },
  // { path: '/privacy_agreement', element: <PrivacyAgreement /> },
  // { path: '/usage_agreement', element: <UsageAgreement /> },
  // { path: '/charge_agreement', element: <ChargeAgreement /> },
  // { path: '/discord_callback', element: <DiscordCallback /> },
  { path: '*', element: <NotFound /> },
]);
