import React from 'react';
import { CircularProgress } from '@mui/material';

import styles from './index.module.scss';

const Loading = () => {
  return (
    <>
      <div className={styles.loading}>
        <CircularProgress style={{ width: '160px', height: '160px' }} />
      </div>
    </>
  );
};

export default Loading;
